import "./App.css";
import "./styles/Footer.css";
import "./styles/Header.css";
import "./styles/MainBody.css";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import CartPage from "./pages/cart-page/CartPage";
import Footer from "./Components/Footer/Footer";
import ProductPage from "./pages/product-page/ProductPage";
import CatalogPage from "./pages/catalog/CatalogPage";
import FilterContextProvider from "./Context/FilterContext";
import AdminPage from "./pages/admin/AdminPage";
import ToggleNavBar from "./Components/Header/ToggleNavBar";
import DataContextProvider from "./Context/DataContext";
import ScrollToTop from "./ScrollToTop";
import SignUp from "./pages/authentication/signup/SignUp";
import LogIn from "./pages/authentication/Log-In/LogIn";
import { useDispatch, useSelector } from "react-redux";
import { checkIsAuthenticated } from "./store/user/UserActions";
import Activate from "./pages/authentication/signup/Activate";
import ResetPassword from "./pages/authentication/Log-In/ResetPassword";
import ResetPasswordConfirm from "./pages/authentication/Log-In/ResetPasswordConfirm";
import PageNotFound from "./PageNotFound";
import { fetchCart } from "./store/cart/CartActions";

function App() {
  const dispatch = useDispatch();

  const { is_staff } = useSelector((state) => state.user?.user ?? false);

  useEffect(() => {
    dispatch(checkIsAuthenticated()).then((_) => {
      dispatch(fetchCart());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="App">
      <DataContextProvider>
        <FilterContextProvider>
          <Router>
            <ScrollToTop />
            <ToggleNavBar></ToggleNavBar>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/cart" element={<CartPage />} />
              <Route exact path="/product/:id" element={<ProductPage />} />
              <Route path="/catalog" element={<CatalogPage />} />
              <Route exact path="/login" element={<LogIn />} />
              <Route exact path="/signup" element={<SignUp />} />
              {/* React router does partial matching, so /users partially matches /users/create, so it would incorrectly return the Users route again! The exact param disables the partial matching for a route and makes sure that it only returns the route if the path is an EXACT match to the current url. */}
              <Route
                exact
                path="/activate/:uid/:token"
                element={<Activate />}
              />
              <Route exact path="/reset_password" element={<ResetPassword />} />
              <Route
                exact
                path="/password/reset/confirm/:uid/:token"
                element={<ResetPasswordConfirm />}
              />
              <Route exact path="/admin/" element={<AdminPage />} />
              {is_staff && (
                <>
                  <Route exact path="/admin/:nm" element={<AdminPage />} />
                  <Route
                    exact
                    path="/admin/:nm/add/"
                    element={<AdminPage addItem={true} />}
                  />
                  <Route
                    exact
                    path="/admin/:nm/:pk"
                    element={<AdminPage changeItem={true} />}
                  />
                </>
              )}
              <Route exact path="/:category" element={<Home />} />
              <Route exact path="*" element={<PageNotFound />} />
            </Routes>
            {/* Footer to be accessible in all pages */}
            <Footer />
          </Router>
        </FilterContextProvider>
      </DataContextProvider>
    </div>
  );
}

export default App;
