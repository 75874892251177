import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import StarRating from "./StarRating";
import { useDispatch, useSelector } from "react-redux";
import { alterCart } from "../../store/cart/CartActions";

const ProductContainer = ({
  product,
  showBtn = false,
  refetchItem = false,
}) => {
  const [percentOff, setPercentOff] = useState(0);

  const dispatch = useDispatch();

  const { loading, shopping_cart } = useSelector((state) => state.cart);

  useEffect(() => {
    function calculatePercentOff() {
      let perc = 0;
      if (product) {
        if (product.price && product.regular_price) {
          perc =
            ((product.regular_price - product.price) / product.regular_price) *
            100;
        }
      }

      return perc;
    }
    setPercentOff(calculatePercentOff());
  }, [product]);

  return (
    <div className="prodContainer">
      <Link
        to={`/product/${product.id}`}
        state={{ item: refetchItem ? null : product }}
      >
        <img
          src={`${process.env.REACT_APP_API_URL}\\${
            product.images[0]?.src ?? ""
          }`}
          alt={product.images[0]?.alt ?? ""}
        />
        <p className="name">{product.name}</p>
        <p className="prodPrice">Ksh. {product.price}</p>
        {percentOff >= 1 ? (
          <p>
            <span className="prodRegPrice">{product.regular_price}</span>
            <span className="prodPercOff"> -{percentOff.toFixed(1)}% </span>
          </p>
        ) : (
          <></>
        )}
        {product.rating_count >= 1 ? (
          <StarRating key={product.id} value={product.average_rating} />
        ) : (
          <></>
        )}
      </Link>
      {showBtn ? (
        (shopping_cart || []).some((c) => c.prod_id === product.id) ? (
          <Button variant="secondary" disabled>
            ADDED
          </Button>
        ) : (
          <Button
            variant="warning"
            onClick={() =>
              dispatch(
                alterCart({
                  prod_id: product.id,
                  quantity: 1,
                  meta_data: [],
                })
              )
            }
          >
            {loading ? "ADDING ..." : "ADD TO CART"}
          </Button>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProductContainer;
