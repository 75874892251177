import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { Link } from "react-router-dom";

const Corousel = () => {
  const [featuredItems, setFeaturedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchFeatured() {
      setLoading(true);

      await axios
        .get(`${process.env.REACT_APP_API_URL}/filter/?featured=1`)
        .catch((_) => {})
        .then((response) => {
          if (response && response.status === 200) {
            setFeaturedItems(response.data);
          }
        });
      setLoading(false);
    }

    fetchFeatured();
  }, []);

  const responsive = {
    any: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="corousel">
      <Carousel
        arrows={false}
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        focusOnSelect={true}
        transitionDuration={4500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {loading ? (
          <div className="c-item">
            <div className="c-overlay">
              <span className="c-featured">Featured</span>
              <a href="/" className="c-shopnow">
                Shop Now
              </a>
            </div>
            <div className="banner_image_placeholder"></div>
          </div>
        ) : (
          featuredItems.map((f, index) => {
            return (
              <div className="c-item" key={index}>
                <div className="c-overlay">
                  <span className="c-featured">Featured</span>
                  <Link
                    to={`/product/${f.id}`}
                    state={{ item: f }}
                    className="c-shopnow"
                  >
                    Shop Now
                  </Link>
                </div>
                <img
                  src={`${process.env.REACT_APP_API_URL}\\${
                    f.images[0]?.src ?? ""
                  }`}
                  alt={f.images[0]?.alt ?? ""}
                />
              </div>
            );
          })
        )}
      </Carousel>
    </div>
  );
};

export default Corousel;
