import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Corousel from "./Corousel";
import BannerLinksBox from "./BannerLinksBox";
import { List } from "react-bootstrap-icons";

const Banner = () => {
  const [toggleDisplay, setToggleDisplay] = useState(false);

  return (
    <div className="banner">
      <Container>
        <Row>
          <Button
            variant="outline-secondary"
            className="toggle-categories-btn"
            onClick={() => {
              setToggleDisplay(!toggleDisplay);
            }}
          >
            <List /> Categories
          </Button>
        </Row>
        <Row>
          <Col sm={4}>
            <BannerLinksBox
              toggleDisplay={toggleDisplay}
              setToggleDisplay={setToggleDisplay}
            />
          </Col>
          <Col sm={8}>
            <Corousel />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
