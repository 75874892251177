import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PriceRange from "../../Components/Body/PriceRange";
import SearchSelectList from "../../Components/Body/SearchSelectList";
import { FilterContext } from "../../Context/FilterContext";
import PageProductsListing from "../../Components/Body/PageProductsListing";
import { DataContext } from "../../Context/DataContext";

const CatalogPage = () => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const search = urlParams.get("q");

  const { categoriesList, colorsList, brandsList } = useContext(DataContext);

  // filter context

  const {
    minPrice,
    maxPrice,
    brands,
    colors,
    setColors,
    setBrands,
    setMinPrice,
    setMaxPrice,
  } = useContext(FilterContext);
  return (
    <div className="catalogPage">
      <Container className="customContainer">
        <Row className="customRow">
          <Col sm={4} className="pageFilters catalogPageFilters">
            <h3>CATEGORY</h3>
            <div className="categLvlLinks">
              {categoriesList.map((category, index) => {
                return category.top_level ? (
                  <Link
                    to={`/${category.name}/?q=${search}`}
                    key={index}
                    className="topLvlCategLinks"
                  >
                    {category.name}
                  </Link>
                ) : (
                  <></>
                );
              })}
            </div>
            <hr />
            <h5>PRICE(KSH)</h5>
            <PriceRange
              minPrice={minPrice}
              maxPrice={maxPrice}
              setUpMax={setMaxPrice}
              setUpMin={setMinPrice}
            />
            <hr></hr>
            <h5>BRAND</h5>
            <SearchSelectList
              items={brandsList}
              keyName="name"
              setSelected={setBrands}
              selectedItems={brands}
            />
            <hr></hr>
            <h5>COLOR</h5>
            <SearchSelectList
              items={colorsList}
              keyName={"value"}
              setSelected={setColors}
              selectedItems={colors}
            />
          </Col>
          <Col sm={8} className="pageProductsListing">
            <PageProductsListing
              brand={brands}
              color={colors}
              minPrice={minPrice}
              maxPrice={maxPrice}
              search={search}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CatalogPage;
